import React, { useState } from "react";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import "../styles/contactme.css";

const ContactMe = () => {
  const [loading, setLoading] = useState(false);

  const formButton = (e: any) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  };

  return (
    <Layout>
      <section
        aria-label="contact-me"
        className="flex flex-col w-full justify-center pt-10 mybackground"
      >
        <h1 className="text-xl md:text-7xl font-mono text-white font-bold w-full text-center">
          CONTACT ME
        </h1>

        <form
          action="get"
          className="flex flex-col w-full justify-center items-center"
          onSubmit={formButton}
        >
          <div className="flex flex-col gap-4 mt-10 w-1/3">
            <div className="flex items-center gap-4 w-full">
              <input
                className="w-full"
                type="email"
                name="email"
                id="email"
                placeholder="email@gmail.com"
              />
            </div>
            <div className="w-full">
              <textarea
                className="w-full"
                id="text-area"
                name="text-area"
                rows={4}
                cols={10}
                placeholder="text"
              ></textarea>
            </div>
            {loading ? (
              <button
                type="submit"
                disabled={true}
                className="outline outline-2 outline-black 
              py-4 px-2 font-mono 
              bg-black  text-white"
              >
                sending...
              </button>
            ) : (
              <button
                type="submit"
                className="outline outline-2 outline-black 
            py-4 px-2 font-mono font-bold text-xl 
            hover:bg-black transition duration-200 hover:text-white"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </section>
    </Layout>
  );
};

export default ContactMe;
